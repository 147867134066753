export class Autocomplete {
    _inputField
    _onPlaceNavigationSelected
    _autocompleteService
    _currentFocus
    _selectedPlace

    //The country Code musts be a ISO 3166-1 alpha-3 code
    constructor(autocompleteService, inputField, countryCode, onPlaceNavigationSelected) {
        this._autocompleteService = autocompleteService
        this._inputField = inputField
        this._onPlaceNavigationSelected = onPlaceNavigationSelected
        this._selectedPlace = null

        let self = this;
        this._inputField.addEventListener("input", function (e) {
            if (!this.value) {
                return false
            }
            let params = {q: this.value}
            if (countryCode) {
                params.in = "countryCode:" + countryCode
            }
            self._autocompleteService.geocode(params, (places) => {
                self._closeAllLists();
                self._currentFocus = -1;

                if (places && places.items) {
                    places = places.items;

                    let list = document.createElement("div");
                    list.setAttribute("id", this.id + "autocomplete-list");
                    list.setAttribute("class", "dmp-autocomplete-items");

                    this.parentNode.appendChild(list);

                    for (let place of places) {
                        let entry = document.createElement("DIV");
                        entry.innerHTML = place.title;
                        entry.innerHTML += "<input type='hidden' value='" + place + "'>";
                        entry.addEventListener("click", function (e) {
                            self._inputField.value = place.title;
                            self.setPlace(place)
                            self._closeAllLists();
                            self._onPlaceNavigationSelected()
                        });
                        list.appendChild(entry);
                    }
                }
            })
        })

        /*execute a function presses a key on the keyboard:*/
        this._inputField.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode === 40) { //down
                self._currentFocus++;
                self._addActive(x);
            } else if (e.keyCode === 38) { //up
                self._currentFocus--;
                self._addActive(x);
            } else if (e.keyCode === 13) { //enter
                e.preventDefault();
                if (self._currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[self._currentFocus].click();
                }
            }
        });
    }

    _closeAllLists(element) {
        var x = document.getElementsByClassName("dmp-autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (element != x[i] && element != this._inputField) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    _addActive(x) {
        if (!x) return false;
        this._removeActive(x);
        if (this._currentFocus >= x.length) this._currentFocus = 0;
        if (this._currentFocus < 0) this._currentFocus = (x.length - 1);
        x[this._currentFocus].classList.add("dmp-autocomplete-active");
    }

    _removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (let i = 0; i < x.length; i++) {
            x[i].classList.remove("dmp-autocomplete-active");
        }
    }

    setPlace(place) {
        this._selectedPlace = place
    }

    getPlace() {
        return this._selectedPlace
    }
}
