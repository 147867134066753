window.VWFS = window.VWFS || {};
VWFS.poiSelector = VWFS.poiSelector || {};
VWFS.poiSelector.ResultListItemFactory = function (poi, resultListTemplate, openingHoursLabel,
                                                   addOpeningHoursToList, toggleOpeningHoursList,
                                                   infoWindowShowButtonWebsiteEnabled, onResultListClick,
                                                   isLastElement, labelWebsiteLink, elementAddressEnabled ,phoneEnabled, emailEnabled) {

    let resultListItemNode;

    this.buildResultListItem = function () {

        resultListItemNode = resultListTemplate.content.cloneNode(true);

        // place image in node
        if (poi.image) {
            resultListItemNode.querySelector("[data-result-list-image]").src = poi.image;
        } else {
            resultListItemNode.querySelector("[data-result-list-image-container]").remove()
        }

        // replace title in node
        resultListItemNode.querySelector("[data-result-list-title]").textContent = poi.title;

        // replace address in node
        if (elementAddressEnabled && poi.address) {
            resultListItemNode.querySelector("[data-result-list-street]").textContent = poi.address.street + " " + poi.address.houseNumber;
            resultListItemNode.querySelector("[data-result-list-city]").textContent = poi.address.zipCode + " " + poi.address.city;
        } else {
            resultListItemNode.querySelector("[data-result-list-street]").remove();
            resultListItemNode.querySelector("[data-result-list-city]").remove();
        }

        // replace opening times in node
        if (poi.openTime && poi.openTime.length > 0) {

            const openingHoursListNode = resultListItemNode.querySelector("[data-result-list-opening-hours-list]");

            resultListItemNode.querySelector("[data-result-list-opening-hours-title]").textContent = openingHoursLabel;

            let dayCounter = addOpeningHoursToList(poi, openingHoursListNode, "u-font-size-fs-1");

            if (!dayCounter) {
                resultListItemNode.querySelector("[data-result-list-opening-hours]").remove();
            } else {
                let openingHoursContainer = resultListItemNode.querySelector("[data-result-list-opening-hours]");
                let openingHoursIcon = resultListItemNode.querySelector("[data-result-list-opening-hours-icon]");
                openingHoursContainer.addEventListener('toggle',
                    () => toggleOpeningHoursList(openingHoursContainer, openingHoursIcon));
            }

        } else {
            resultListItemNode.querySelector("[data-result-list-opening-hours]").remove();
        }

        // replace phone number in node
        if (phoneEnabled && poi.contactData && poi.contactData.telephoneNumber) {

            const phoneNumber = (poi.contactData.telephoneCountryCode ? poi.contactData.telephoneCountryCode + " " : "")
                + poi.contactData.telephoneNumber;
            resultListItemNode.querySelector("[data-result-list-phone]").href = "tel:" + phoneNumber.replaceAll(" ", "");
            resultListItemNode.querySelector("[data-result-list-phone-text]").textContent = phoneNumber;
        } else {
            resultListItemNode.querySelector("[data-result-list-phone]").remove();
        }

        // replace email address in node
        if (emailEnabled && poi.contactData && poi.contactData.emails && poi.contactData.emails.length > 0) {
            resultListItemNode.querySelector("[data-result-list-email]").href = "mailto:" + poi.contactData.emails[0];
            resultListItemNode.querySelector("[data-result-list-email-text]").textContent = poi.contactData.emails[0];
        } else {
            resultListItemNode.querySelector("[data-result-list-email]").remove();
        }

        // replace website in node
        let websiteLink = resultListItemNode.querySelector("[data-result-list-website]");

        if (infoWindowShowButtonWebsiteEnabled && poi.link && poi.link.url) {

            // set values on link
            if (poi.link.disclaimer) {
                websiteLink.setAttribute("data-disclaimer-ref", poi.link.disclaimer);
            }
            websiteLink.target = poi.link.target;
            websiteLink.href = poi.link.url;
            websiteLink.title = poi.link.title || poi.link.url;
            websiteLink.setAttribute("data-tr-component", "Link");
            try {
                new VWFS.LinkHandler(websiteLink);
            } catch (e) {
                console.error("An error while creating a new LinkHander instance: {}", e);
            }

            // set title to configured string
            resultListItemNode.querySelector("[data-result-list-website-text]").textContent = labelWebsiteLink;

        } else {
            websiteLink.remove();
        }

        // add id and event listener to button and label
        let button = resultListItemNode.querySelector("[data-result-list-button]");
        button.id = poi.poiUuid;
        button.poi = poi;

        let label = resultListItemNode.querySelector("[data-result-list-button]");
        label.for = poi.poiUuid;
        label.addEventListener('click', (e) => onResultListClick(e.target, true));

        if (isLastElement) {
            resultListItemNode.querySelector("hr").remove();
        }

        return resultListItemNode;
    }
}
