export class MarkerClustering {
    _centerMarker;
    _markerCluster;
    _dataPoints;
    _map;

    constructor(map, ui, clickOnPointOfInterest, markerClusterImage, pinIconSvg, pinSelectedIconSvg) {
        this._map = map;
        let markerIcon = new H.map.Icon(pinIconSvg, {size: {w: 42, h: 60}});
        let markerIconSelected = new H.map.Icon(pinSelectedIconSvg ? pinSelectedIconSvg : pinIconSvg, {
            size: {
                w: 42,
                h: 60
            }
        });

        let sizes = [53, 56, 66, 78, 90];
        let clusterElements = [];

        for (let i = 0; i < sizes.length; i++) {
            let element = this.requestSvgElement(markerClusterImage + (i + 1) + '.svg');
            clusterElements.push(element)
        }

        this._markerCluster = new H.clustering.Provider([], {
            clusteringOptions: {
                strategy: H.clustering.Provider.Strategy.GRID, eps: 60, // Maximum radius of the neighbourhood
                minWeight: 2, // minimum weight of points required to form a cluster
            }, theme: {
                getClusterPresentation: (cluster) => {

                    let textContent = cluster.getWeight().toString();

                    let clusterIcons = []
                    for (let i = 0; i < sizes.length; i++) {
                        let size = sizes[i]


                        let element = clusterElements[i];
                        let text = element.querySelector("text");
                        if (text) {
                            text.textContent = textContent;
                        }
                        let svg = element.querySelector("svg");

                        if (svg) {
                            clusterIcons.push(new H.map.Icon(svg.outerHTML, {size: {w: size, h: size}}));
                        }
                    }

                    // Use cluster weight to change the icon size
                    let weight = this._calculateWeight(cluster.getWeight(), sizes.length)

                    let clusterMarker = new H.map.Marker(this._calculateCenterOfCluster(cluster), {
                        icon: clusterIcons[weight.index], min: cluster.getMinZoom(), max: cluster.getMaxZoom()
                    })
                    let zoomStep = 2;
                    clusterMarker.addEventListener('tap', () => {
                        this._map.getViewModel().setLookAtData({zoom: cluster.getMaxZoom()+zoomStep, bounds: cluster.getBoundingBox()}, true)
                    })

                    // Create a marker for the cluster
                    return clusterMarker;
                }, getNoisePresentation: (noisePoint) => {
                    let selected = noisePoint.getData().selected
                    // Create a marker for noise points:
                    let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                        icon: selected ? markerIconSelected : markerIcon, min: noisePoint.getMinZoom()
                    });
                    noiseMarker.addEventListener('tap', clickOnPointOfInterest(noisePoint.getPosition(), noisePoint.getData(), ui, noiseMarker))
                    return noiseMarker;
                }
            }
        });

        // Create a layer that includes the data provider and its data points:
        let layer = new H.map.layer.ObjectLayer(this._markerCluster);

        // Add the layer to the map:
        this._map.addLayer(layer);
    }

    requestSvgElement(markerClusterImage) {
        let element = document.createElement("div");
        var request = new XMLHttpRequest()
        request.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200 && this.responseText) {
                    return element.innerHTML = this.responseText;
                } else {
                    return null;
                }
            }
        }

        request.open('GET', markerClusterImage, true)
        request.send()
        return element;
    }

    _calculateWeight(weight, numStyles) {
        let index = 0;
        let dv = weight;
        while (dv !== 0) {
            dv = parseInt(dv / 10, 10);
            index++;
        }

        index = Math.min(index, numStyles);
        return {
            text: weight, index: index
        };
    }

    _calculateCenterOfCluster(cluster) {
        let count = 0;
        let lat = 0;
        let lng = 0;
        cluster.forEachDataPoint((point) => {
            lat += point.getPosition().lat
            lng += point.getPosition().lng
            count++;
        })

        return {lat: lat / count, lng: lng / count}
    }

    fitMapToMarkers() {
        let points = []
        if (this._dataPoints) {
            points = [...this._dataPoints]
        }
        if (this._centerMarker) {
            points.push(this._centerMarker.getGeometry())
        }

        if (points.length > 0) {
            let bounds = H.geo.Rect.coverPoints(points)
            this._map.getViewModel().setLookAtData({bounds: bounds}, false)
        }
    }

    setCenterMarker(pos) {
        if (!this._centerMarker) {
            this._centerMarker = new H.map.Marker(pos);
            this._map.addObject(this._centerMarker)
        } else {
            this._centerMarker.setGeometry(pos);
        }
    }

    getCenterMarker() {
        return this._centerMarker
    }

    setDataPoints(dataPoints) {
        this._markerCluster.setDataPoints(dataPoints);
    }

    setMarkers(pointsOfInterests) {
        this._dataPoints = pointsOfInterests.filter((poi) => poi && poi.coordinates && poi.coordinates.latitude && poi.coordinates.longitude).map((poi) => {
            return new H.clustering.DataPoint(parseFloat(poi.coordinates.latitude), parseFloat(poi.coordinates.longitude), null, poi);
        })

        this._markerCluster.setDataPoints(this._dataPoints);
    }
}
