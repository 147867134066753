window.VWFS = window.VWFS || {};
VWFS.poiSelector = VWFS.poiSelector || {};
VWFS.poiSelector.InfoContentFactory = function (pointOfInterest, infoWindowShowButtonWebsiteEnabled, showButtonApply, buttonApplyText, getOpenTime, getAddress,getContactData, labelContentLink) {

    var container;

    /**
     * BUILD INFO CONTENT
     * Creates InfoContent-Element to show it inside the map.
     *
     * @returns {HTMLDivElement}
     */
    this.buildInfoContent = function () {

        if (!pointOfInterest) {
            return null;
        }

        // CREATE CONTAINER
        container = document.createElement('div');
        container.classList.add('iw-container');

        // CREATE CONTENT AND ADD TO CONTAINER
        var content = document.createElement('div');
        content.classList.add('iw-content');
        container.appendChild(content);

        // CREATE HEADER WITH PICTURE AND TITLE
        var header = document.createElement('p');
        header.classList.add('u-mb-xsmall', 'iw-header');
        if (pointOfInterest.image) {

            // CREATE IMAGE AND ADD TO CONTENT
            header.appendChild(getInfoContentImage());

        }
		content.appendChild(header);

        // CREATE TITLE AND ADD TO CONTENT
        var title = document.createElement('div');
        title.innerHTML = pointOfInterest.title;
        title.classList.add("iw-title")
        header.appendChild(title);

        if (pointOfInterest.description) {

            // CREATE DESCRIPTION AND ADD TO CONTENT
            content.appendChild(getInfoContentDescription());

        }

        if (pointOfInterest.address && getAddress(pointOfInterest)) {
            // CREATE ADDRESS AND ADD TO CONTENT
            content.appendChild(getAddress(pointOfInterest));
        }

        if (pointOfInterest.openTime && getOpenTime(pointOfInterest)) {
            // CREATE OPENING HOURS AND ADD TO CONTENT
            content.appendChild(getOpenTime(pointOfInterest));
        }
        if (pointOfInterest.contactData && getContactData(pointOfInterest)) {
            // CREATE CONTACT DATA AND ADD TO CONTENT
            content.appendChild(getContactData(pointOfInterest));
        }

        if (infoWindowShowButtonWebsiteEnabled && pointOfInterest.link && pointOfInterest.link.url) {

            // CREATE LINK AND ADD TO CONTENT
            content.appendChild(getInfoContentLink());

        }

        if(showButtonApply && !pointOfInterest.disabled) {

            // CREATE APPLY-BUTTON AND ADD TO CONTENT
            content.appendChild(getInfoContentButtonApply());

        }

        return container;

    }

    /**
     * GET INFO CONTENT DESCRIPTION
     * Creates info content for description if pointOfInterest.description exist
     */

    var getInfoContentDescription = function () {

        var template = document.createElement('template');
        template.innerHTML = pointOfInterest.description.trim();
        template.content.firstChild.classList.add("u-mb-xsmall");

        return template.content.firstChild;
    }

    /**
     * GET INFO CONTENT IMAGE
     * Creates Info Content for image if pointOfInterest.image exist
     */
    var getInfoContentImage = function () {

        var imageElement = document.createElement('img');
        imageElement.setAttribute('src', pointOfInterest.image);

        return imageElement;

    }

    /**
     * GET INFO CONTENT LINK
     * crate info content for link if pointOfInterest.link exist
     */
    var getInfoContentLink = function () {

        // CREATE LINK ELEMENT
        var linkElement = document.createElement("a");
        linkElement.classList.add("c-btn");
        linkElement.classList.add("c-btn--link");
        linkElement.classList.add("c-btn--small");
        linkElement.setAttribute("data-tr-component", "Link")

        var disclaimerLink = pointOfInterest.link.disclaimer;
        if (disclaimerLink) {
            linkElement.setAttribute("data-disclaimer-ref", disclaimerLink);
        }
        var title = pointOfInterest.link.title;
        if (title) {
            linkElement.setAttribute("title", title);
        }

        linkElement.setAttribute("target", pointOfInterest.link.target);
        linkElement.setAttribute("href", pointOfInterest.link.url);

        try {
            new VWFS.LinkHandler(linkElement);
        } catch (e) {
            console.error("An error while creating a new LinkHander instance: {}", e);
        }

        // CREATE SPAN-ELEMENT AND ADD TO LINK
        var span = document.createElement("span");
        span.classList.add("c-btn__text");
        span.innerText = labelContentLink();
        linkElement.appendChild(span);

        // CREATE ICON-ELEMENT AND ADD TO LINK
        var icon = document.createElement("i");
        icon.classList.add("c-btn__icon");
        icon.classList.add("c-icon");
        icon.classList.add("c-icon--[semantic-forward]");
        linkElement.appendChild(icon);

        return linkElement;

    }


    /**
     * GET INFO CONTENT BUTTON APPLY
     */
    var getInfoContentButtonApply = function () {

        // CREATE DIV
        var buttonContainer = document.createElement("div");
        buttonContainer.classList.add("o-button-container");
        buttonContainer.classList.add("u-mt-small");
        buttonContainer.classList.add("o-button-container--left");

        // CREATE BUTTON AND ADD TO DIV
        var button = document.createElement("a");
        button.classList.add("c-btn");
        button.classList.add("c-btn--secondary");
        button.pointOfInterest = pointOfInterest;
        buttonContainer.appendChild(button);

        // ADD SPAN AND ADD TO BUTTON
        var span = document.createElement("span");
        span.classList.add("c-btn__text");
        span.innerText = buttonApplyText;
        button.appendChild(span);

        // ADD LISTENER TO BUTTON
        button.addEventListener('click', onApplyPoi);        // container

        return buttonContainer;

    }



    // ----------------------------------------------------------
    // EVENTS
    // ----------------------------------------------------------


    /**
     * EVENT > ON APPLY POI
     */
    var onApplyPoi = function (event) {

        // DISPATCH EVENT
        VWFSUtils.dispatchEvent(container, 'applyPoi', {detail: {pointOfInterest: event.currentTarget.pointOfInterest}});

    }

};
